import React from 'react';
import Logo from './logo';
import Footer from './footer'
const Landing = () => {
	return (
		<div className='landing-logo'>
			<Logo />
			<div className='landing-mid-panel'>
				<div className='presentation'>
					<h2>Je suis un développeur <div className='typescript'>TypeScript</div> et <div className='javascript'>JavaScript</div> spécialisé en Discord.js. Faîtes appel à moi pour toute demande</h2>
				</div>
				<div className='presentation-2'>
					<h2>À votre service</h2>
					<h2>Rapide</h2>
					<h2>À l'écoute</h2>
				</div>
			</div>
			<Footer />
		</div>
	);
}

export default Landing;
