import React from 'react';


const PrivacyPolicy = () => {
	return (
		<div>
		<div id="privacy-policy">
		<main >
				<section>
					<h2>1. Informations collectées</h2>
					<p>Oxyrobot peut collecter certaines informations sur les utilisateurs pour offrir ses services. Cela peut inclure des informations telles que les ID d'utilisateur Discord, les messages envoyés au bot, etc.</p>
				</section>
				
				<section>
					<h2>2. Utilisation des informations</h2>
					<p>Les informations collectées sont utilisées uniquement pour fournir les fonctionnalités du bot et améliorer l'expérience utilisateur. Nous ne vendons pas vos informations à des tiers.</p>
				</section>

				<section>
					<h2>3. Sécurité des données</h2>
					<p>Nous prenons la sécurité de vos informations au sérieux et utilisons des mesures de protection pour empêcher l'accès non autorisé à vos données.</p>
				</section>

				<section>
					<h2>4. Modifications de la Politique de Confidentialité</h2>
					<p>Nous nous réservons le droit de modifier cette politique de confidentialité à tout moment. Toute modification sera affichée sur cette page. Il est de votre responsabilité de vérifier cette politique régulièrement.</p>
				</section>

				<section>
					<h2>5. Contact</h2>
					<p>Pour toute question concernant cette politique de confidentialité, veuillez nous contacter à : contact@oxyrobot.fr</p>
				</section>
    	</main>
		</div></div>
		
	);
}

export default PrivacyPolicy;
