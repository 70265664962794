import React from 'react';

const Tos = () => {
	return (
	  <div id="tos">
		<main>
		  <section>
			<h2>Conditions d'Utilisation de Oxyrobot</h2>
			<p>Dernière mise à jour : 20/10/2024 </p>
			<p>
			  En utilisant Oxyrobot (le « Bot »), vous acceptez les présentes conditions d'utilisation (« Conditions »). Si
			  vous n'acceptez pas ces Conditions, veuillez cesser d'utiliser Oxyrobot. Nous nous réservons le droit de
			  modifier ces Conditions à tout moment sans préavis. Il est de votre responsabilité de vérifier les mises à
			  jour régulièrement.
			</p>
		  </section>
  
		  <section>
			<h2>1. Utilisation du Bot</h2>
			<p>
			  Le Bot est conçu pour fournir diverses fonctionnalités sur les serveurs Discord. En utilisant Oxyrobot, vous
			  acceptez de suivre les règles suivantes :
			</p>
			<ul>
			  <li>Vous ne devez pas utiliser le Bot à des fins illégales ou non autorisées.</li>
			  <li>
				Vous ne devez pas tenter de perturber ou d'exploiter les fonctionnalités du Bot de manière malveillante.
			  </li>
			  <li>
				Vous devez respecter les conditions d'utilisation de Discord lors de l'utilisation de Oxyrobot, notamment
				en ce qui concerne le respect des autres utilisateurs et la non-violation de la vie privée.
			  </li>
			</ul>
		  </section>
  
		  <section>
			<h2>2. Collecte et Utilisation des Données</h2>
			<p>
			  Oxyrobot peut collecter des informations telles que les identifiants Discord, les messages échangés avec le
			  bot et d'autres données nécessaires à son bon fonctionnement. Ces informations sont utilisées uniquement
			  pour fournir les fonctionnalités du Bot et améliorer l'expérience utilisateur.
			</p>
			<p>Nous ne partageons ni ne vendons vos données à des tiers.</p>
		  </section>
  
		  <section>
			<h2>3. Limitation de Responsabilité</h2>
			<p>
			  Oxyrobot est fourni « tel quel » sans garantie d'aucune sorte. Nous ne pouvons être tenus responsables des
			  dommages directs ou indirects résultant de l'utilisation ou de l'impossibilité d'utiliser Oxyrobot.
			</p>
		  </section>
  
		  <section>
			<h2>4. Modifications des Conditions d'Utilisation</h2>
			<p>
			  Nous nous réservons le droit de modifier ces Conditions à tout moment. Les modifications seront effectives
			  dès leur publication sur cette page. Il est de votre responsabilité de consulter régulièrement les
			  Conditions mises à jour.
			</p>
		  </section>
  
		  <section>
			<h2>5. Contact</h2>
			<p>
			  Si vous avez des questions concernant ces Conditions d'utilisation, veuillez nous contacter à l'adresse
			  suivante : contact@oxyrobot.fr
			</p>
		  </section>
		</main>
	  </div>
	);
  };
  
export default Tos;
