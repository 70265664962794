import React from 'react';

const Footer = () => {
	return (
		<div className='landing-links landing-mid-panel'>
			<a href="https://discord.com/application-directory/1051859762838515843" id='fir'>Discord</a>
			<a href="https://github.com/Oxyrobit/" id='sec'>Github</a>
			<a href="/terms-of-service" id='frt'>Terms Of Service</a>
			<a href="/privacy-policy" id='fiv'>Politique de confidentialité</a>
            <a href="mailto:contact@oxyrobot.fr" id='thi'>Contact</a>
		</div>
	);
}

export default Footer;