// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fonts/Quicksand-SemiBold.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "Quicksand";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
body {
  margin: 0;
  height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0f0f0f;
  background-image: linear-gradient(345deg, #101111 0%, #000000 74%);
  background-size: cover;
  background-repeat: no-repeat;
}`, "",{"version":3,"sources":["webpack://./src/index.scss"],"names":[],"mappings":"AAAA;EACI,wBAAA;EACA,4CAAA;AACJ;AAEA;EACI,SAAA;EACA,aAAA;EACA,mCAAA;EACA,kCAAA;EACA,yBAAA;EACA,kEAAA;EACA,sBAAA;EACA,4BAAA;AAAJ","sourcesContent":["@font-face {\r\n    font-family: 'Quicksand';\r\n    src: url('fonts/Quicksand-SemiBold.ttf');\r\n}\r\n\r\nbody {\r\n    margin: 0;\r\n    height: 100vh;\r\n    -webkit-font-smoothing: antialiased;\r\n    -moz-osx-font-smoothing: grayscale;\r\n    background-color: #0f0f0f;\r\n    background-image: linear-gradient(345deg, #101111 0%, #000000 74%);\r\n    background-size: cover;\r\n    background-repeat: no-repeat;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
