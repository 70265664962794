import './App.scss';
import Landing from './components/landing';
import React from 'react';
import { Route, Routes, BrowserRouter} from 'react-router-dom';
import Tos from './components/tos';
import PrivacyPolicy from './components/PrivacyPolicy';

function App() {
    return ( 
        <div id='App'>
             <BrowserRouter>
                <Routes>
                    <Route path='/' element={<Landing />}/>
                    <Route path='/terms-of-service' element={<Tos />}/>
                    <Route path='/privacy-policy' element={<PrivacyPolicy />}/>
                </Routes>
             </BrowserRouter>
        </div>
    );
}

export default App;