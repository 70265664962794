import React from 'react';
import './style/logo.css'

const Logo = () => {
	return (
		<div id='logo'>
			<div className='logo-with-text'>
			<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
				viewBox="0 0 381.66 97.24" xmlSpace="preserve">
			<g>
				<g>
					<g>
						<path className="st7" d="M105.79,50.62c-0.04-0.02-0.08-0.05-0.16-0.1c-0.09-0.06-0.17-0.11-0.26-0.17
							c-1.29-0.93-2.12-2.52-2.21-4.28c-0.62-11.98-5.53-22.74-13.21-30.86L85.7,20.7c6.18,6.87,10.12,15.82,10.63,25.72
							c0.14,2.66,1.05,5.13,2.59,7.11c-1.93,1.62-3.36,3.84-4.06,6.35c-1.74,6.23-4.93,11.96-9.24,16.74l4.25,5.48
							c5.44-5.73,9.45-12.74,11.59-20.38c0.36-1.3,1.12-2.42,2.14-3.16c0.35-0.25,0.72-0.46,1.1-0.63c0.16-0.07,0.33-0.14,0.46-0.18
							c1.45-0.51,2.47-1.82,2.62-3.34C107.91,52.89,107.15,51.41,105.79,50.62z"/>
						<path className="st7" d="M12.92,59.82c-0.69-2.5-2.12-4.74-4.05-6.36c1.54-1.98,2.46-4.45,2.61-7.11c0.53-9.81,4.4-18.67,10.49-25.5
							l-4.28-5.47C10.12,23.45,5.27,34.11,4.64,45.97c-0.1,1.77-0.93,3.37-2.22,4.29c-0.08,0.05-0.16,0.11-0.24,0.16l-0.05,0.03
							c-0.05,0.03-0.1,0.06-0.14,0.09c-1.33,0.75-2.11,2.22-1.98,3.75c0.13,1.53,1.15,2.86,2.58,3.37c0.16,0.05,0.31,0.11,0.49,0.19
							c0.38,0.17,0.75,0.38,1.1,0.63c1.02,0.74,1.78,1.87,2.14,3.17c2.13,7.67,6.15,14.7,11.61,20.45l4.25-5.48
							C17.85,71.82,14.65,66.06,12.92,59.82z"/>
					</g>
				</g>
				<g>
					<path className="st7" d="M78.87,82.64c-0.65,0.47-1.32,0.91-1.99,1.34c-1.31,0.83-2.66,1.57-4.03,2.24
						c-5.64,2.75-11.84,4.17-18.23,4.17c-0.26,0-0.52,0-0.79,0l-0.14,0l-0.08,0c-0.17,0-0.34,0-0.5,0c-6.37-0.01-12.53-1.44-18.15-4.17
						h0c-1.38-0.67-2.72-1.42-4.03-2.25l22.62-29.19l0,0l7.58-9.78l21.34-27.54l4.2-5.42c-1.72-1.51-3.54-2.88-5.45-4.13
						c-1.28-0.84-2.61-1.62-3.97-2.34C70.46,2.01,62.76,0,54.61,0c-0.12,0-0.25,0-0.37,0.01h-0.1c-0.02,0-0.04,0-0.05,0
						C53.78,0,53.48,0,53.18,0c-8.23,0-16,2.05-22.81,5.67c-1.36,0.72-2.68,1.51-3.96,2.35c-1.91,1.25-3.73,2.65-5.43,4.16l4.23,5.4
						l21.45,27.44l2.9,3.71l-2.88,3.72l-21.23,27.4l-4.2,5.42c1.16,1.01,2.37,1.98,3.63,2.88c0.6,0.43,1.21,0.85,1.83,1.25
						c1.29,0.84,2.61,1.62,3.97,2.33h0c6.88,3.6,14.52,5.49,22.42,5.51c0.19,0,0.37,0,0.55,0h0.01l0.06,0c0.3,0,0.59,0,0.89,0
						c7.93,0,15.6-1.89,22.5-5.51c1.36-0.71,2.68-1.48,3.97-2.33c0.59-0.38,1.18-0.78,1.76-1.2c1.28-0.92,2.52-1.89,3.7-2.93l-4.2-5.42
						C81.24,80.85,80.08,81.77,78.87,82.64z M30.64,13.43L30.64,13.43c1.29-0.83,2.63-1.59,4.02-2.27c5.59-2.77,11.89-4.32,18.53-4.32
						c0.31,0,0.64,0,0.91,0l0.49,0.01V6.84h0.02c6.57,0,12.8,1.52,18.35,4.23c1.39,0.68,2.73,1.43,4.03,2.26l-20.23,26.1l-2.88,3.72
						l-2.9-3.71L30.64,13.43z"/>
					
						<rect x="61.13" y="60.02" transform="matrix(0.7905 -0.6125 0.6125 0.7905 -24.7446 52.6321)" className="st7" width="6.85" height="4.92"/>
					<polygon className="st7" points="75.42,82.09 80.82,77.9 78.78,75.26 77.81,74.01 72.4,78.19 73.44,79.54 		"/>
					<polygon className="st7" points="59.81,50.77 55.48,56.36 56.77,58.04 57.32,58.74 62.73,54.55 61.1,52.45 		"/>
					
						<rect x="67.16" y="67.8" transform="matrix(0.7905 -0.6125 0.6125 0.7905 -28.2471 57.9574)" className="st7" width="6.84" height="4.92"/>
				</g>
				<g>
					<g>
						<path className="st7" d="M139.46,64.41c-2.4,0-4.61-0.39-6.63-1.18c-2.02-0.79-3.77-1.89-5.26-3.31c-1.49-1.42-2.65-3.09-3.47-5
							c-0.82-1.92-1.23-4.02-1.23-6.3c0-2.28,0.41-4.38,1.23-6.3c0.82-1.91,1.97-3.58,3.47-5c1.49-1.42,3.25-2.52,5.26-3.31
							c2.02-0.79,4.21-1.18,6.58-1.18c2.4,0,4.6,0.39,6.6,1.18c2,0.79,3.75,1.89,5.24,3.31c1.49,1.42,2.65,3.09,3.47,5
							c0.82,1.92,1.23,4.02,1.23,6.3c0,2.28-0.41,4.39-1.23,6.32c-0.82,1.93-1.97,3.6-3.47,5c-1.49,1.4-3.24,2.5-5.24,3.29
							C144.02,64.02,141.83,64.41,139.46,64.41z M139.42,59.41c1.55,0,2.98-0.26,4.3-0.79c1.32-0.53,2.46-1.28,3.42-2.26
							c0.97-0.98,1.72-2.12,2.26-3.42c0.54-1.3,0.81-2.74,0.81-4.32c0-1.58-0.27-3.02-0.81-4.32c-0.54-1.3-1.29-2.44-2.26-3.42
							c-0.97-0.98-2.11-1.73-3.42-2.26c-1.32-0.53-2.75-0.79-4.3-0.79c-1.55,0-2.98,0.26-4.28,0.79c-1.3,0.53-2.44,1.28-3.42,2.26
							c-0.98,0.98-1.74,2.12-2.28,3.42c-0.54,1.3-0.81,2.74-0.81,4.32c0,1.55,0.27,2.98,0.81,4.3c0.54,1.32,1.29,2.46,2.26,3.44
							c0.97,0.98,2.11,1.73,3.42,2.26C136.44,59.15,137.87,59.41,139.42,59.41z"/>
						<path className="st7" d="M160.7,63.98l12.94-17.81v4.3l-12.33-17.2h6.45l9.17,12.72l-2.63,0.04l9.08-12.77h6.19l-12.24,16.89v-4.12
							l13.03,17.95h-6.58l-9.65-13.51h2.59l-9.52,13.51H160.7z"/>
						<path className="st7" d="M204.57,63.98V51.82l1.27,3.51l-13.34-22.07h6.1l10.71,17.77h-3.42l10.79-17.77h5.62l-13.34,22.07l1.32-3.51
							v12.15H204.57z"/>
						<path className="st7" d="M229.1,63.98V33.26h12.64c2.72,0,5.05,0.44,7,1.32c1.95,0.88,3.44,2.14,4.5,3.77
							c1.05,1.64,1.58,3.58,1.58,5.84c0,2.25-0.53,4.19-1.58,5.81c-1.05,1.62-2.55,2.87-4.5,3.73s-4.28,1.29-7,1.29h-9.48l2.54-2.59
							v11.54H229.1z M234.81,53.05l-2.54-2.72h9.21c2.52,0,4.41-0.54,5.68-1.62c1.27-1.08,1.91-2.59,1.91-4.52
							c0-1.96-0.64-3.47-1.91-4.52c-1.27-1.05-3.17-1.58-5.68-1.58h-9.21l2.54-2.81V53.05z M249.2,63.98l-7.77-11.14h6.1l7.81,11.14
							H249.2z"/>
						<path className="st7" d="M279.08,64.41c-2.4,0-4.61-0.39-6.62-1.18c-2.02-0.79-3.77-1.89-5.27-3.31c-1.49-1.42-2.65-3.09-3.47-5
							c-0.82-1.92-1.23-4.02-1.23-6.3c0-2.28,0.41-4.38,1.23-6.3c0.82-1.91,1.97-3.58,3.47-5c1.49-1.42,3.25-2.52,5.27-3.31
							c2.02-0.79,4.21-1.18,6.58-1.18c2.4,0,4.6,0.39,6.6,1.18c2,0.79,3.75,1.89,5.24,3.31c1.49,1.42,2.65,3.09,3.47,5
							c0.82,1.92,1.23,4.02,1.23,6.3c0,2.28-0.41,4.39-1.23,6.32c-0.82,1.93-1.97,3.6-3.47,5c-1.49,1.4-3.24,2.5-5.24,3.29
							C283.63,64.02,281.45,64.41,279.08,64.41z M279.03,59.41c1.55,0,2.98-0.26,4.3-0.79c1.32-0.53,2.46-1.28,3.42-2.26
							c0.97-0.98,1.72-2.12,2.26-3.42c0.54-1.3,0.81-2.74,0.81-4.32c0-1.58-0.27-3.02-0.81-4.32c-0.54-1.3-1.29-2.44-2.26-3.42
							c-0.96-0.98-2.11-1.73-3.42-2.26c-1.32-0.53-2.75-0.79-4.3-0.79c-1.55,0-2.98,0.26-4.28,0.79c-1.3,0.53-2.44,1.28-3.42,2.26
							c-0.98,0.98-1.74,2.12-2.28,3.42c-0.54,1.3-0.81,2.74-0.81,4.32c0,1.55,0.27,2.98,0.81,4.3c0.54,1.32,1.29,2.46,2.26,3.44
							s2.11,1.73,3.42,2.26C276.05,59.15,277.48,59.41,279.03,59.41z"/>
						<path className="st7" d="M305.14,63.98V33.26h14.39c3.69,0,6.49,0.72,8.42,2.17c1.93,1.45,2.9,3.39,2.9,5.81
							c0,1.64-0.38,3.03-1.14,4.19c-0.76,1.16-1.78,2.05-3.05,2.68c-1.27,0.63-2.65,0.94-4.15,0.94l0.79-1.58
							c1.73,0,3.28,0.31,4.65,0.94c1.37,0.63,2.46,1.54,3.27,2.74c0.8,1.2,1.21,2.69,1.21,4.47c0,2.63-1.01,4.68-3.03,6.14
							c-2.02,1.46-5.02,2.19-8.99,2.19H305.14z M310.84,59.5h9.21c2.13,0,3.77-0.35,4.91-1.05c1.14-0.7,1.71-1.83,1.71-3.38
							c0-1.52-0.57-2.64-1.71-3.36c-1.14-0.72-2.78-1.08-4.91-1.08h-9.65v-4.43h8.51c1.99,0,3.52-0.35,4.58-1.05
							c1.07-0.7,1.6-1.76,1.6-3.16c0-1.43-0.53-2.5-1.6-3.2c-1.07-0.7-2.6-1.05-4.58-1.05h-8.07V59.5z"/>
						<path className="st7" d="M342.04,63.98V33.26h5.7v30.71H342.04z"/>
						<path className="st7" d="M365.77,63.98V38.09h-10.18v-4.83h26.06v4.83h-10.18v25.89H365.77z"/>
					</g>
				</g>
			</g>
			</svg>
			</div>
			<div className='logo-without-text'>
			<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 			viewBox="0 0 209.56 189.06" xmlSpace="preserve">
				<g>
					<g>
						<g>
							<path className="st7" d="M205.68,98.42c-0.08-0.04-0.16-0.09-0.32-0.19c-0.17-0.12-0.33-0.22-0.5-0.34c-2.51-1.8-4.12-4.91-4.29-8.33
								c-1.21-23.29-10.76-44.22-25.68-59.99l-8.27,10.67c12.01,13.36,19.67,30.76,20.66,50.01c0.27,5.17,2.05,9.97,5.03,13.83
								c-3.75,3.14-6.53,7.47-7.9,12.34c-3.38,12.11-9.58,23.26-17.97,32.55l8.26,10.66c10.57-11.14,18.38-24.77,22.53-39.63
								c0.7-2.52,2.18-4.7,4.16-6.15c0.67-0.49,1.39-0.9,2.14-1.22c0.32-0.13,0.64-0.27,0.9-0.35c2.81-0.98,4.81-3.53,5.09-6.5
								C209.8,102.83,208.32,99.95,205.68,98.42z"/>
							<path className="st7" d="M25.12,116.3c-1.35-4.87-4.13-9.21-7.88-12.37c3-3.85,4.78-8.65,5.07-13.83
								c1.03-19.06,8.56-36.29,20.39-49.57L34.39,29.9C19.67,45.59,10.25,66.33,9.01,89.38c-0.19,3.43-1.8,6.55-4.32,8.34
								c-0.15,0.11-0.3,0.21-0.46,0.32L4.14,98.1c-0.09,0.05-0.19,0.12-0.27,0.17c-2.59,1.46-4.1,4.32-3.84,7.28
								c0.25,2.98,2.23,5.55,5.02,6.56c0.3,0.11,0.6,0.22,0.96,0.37c0.75,0.33,1.46,0.74,2.13,1.22c1.98,1.45,3.46,3.64,4.16,6.16
								c4.14,14.9,11.95,28.57,22.57,39.75l8.26-10.66C34.71,139.63,28.49,128.44,25.12,116.3z"/>
						</g>
					</g>
					<g>
						<path className="st7" d="M153.34,160.67c-1.27,0.9-2.56,1.77-3.87,2.6c-2.55,1.61-5.16,3.06-7.84,4.36
							c-10.97,5.34-23.01,8.12-35.45,8.12c-0.51,0-1.02,0-1.53-0.01l-0.28-0.01l-0.15,0.01c-0.33,0.01-0.66,0.01-0.98,0
							c-12.38-0.02-24.37-2.8-35.3-8.12h-0.01c-2.69-1.3-5.3-2.77-7.84-4.37l43.97-56.75l0.01-0.01l14.73-19.02l41.48-53.55l8.17-10.54
							c-3.34-2.93-6.88-5.61-10.59-8.04c-2.49-1.63-5.07-3.15-7.72-4.54C137,3.9,122.03,0,106.18,0c-0.24,0-0.48,0.01-0.72,0.02h-0.2
							c-0.04-0.01-0.07-0.01-0.11,0C104.56,0,103.98,0,103.39,0c-16,0-31.11,3.98-44.36,11.03c-2.64,1.4-5.21,2.93-7.69,4.58
							c-3.72,2.44-7.25,5.15-10.57,8.08L49,34.19l41.71,53.35l5.64,7.21l-5.61,7.23l-41.28,53.28l-8.16,10.53
							c2.25,1.97,4.61,3.84,7.06,5.6c1.17,0.83,2.35,1.65,3.55,2.42c2.51,1.64,5.08,3.15,7.72,4.53h0.01
							c13.38,7.01,28.23,10.68,43.59,10.71c0.36,0,0.71,0,1.06-0.01h0.03l0.12,0.01c0.58,0.01,1.15,0.01,1.73,0.01
							c15.41,0,30.32-3.67,43.75-10.71c2.63-1.38,5.21-2.88,7.72-4.52c1.15-0.75,2.3-1.52,3.42-2.32c2.49-1.78,4.9-3.68,7.19-5.7
							l-8.16-10.54C157.95,157.19,155.7,158.99,153.34,160.67z M59.57,26.12L59.57,26.12c2.51-1.61,5.12-3.09,7.82-4.41
							c10.87-5.38,23.11-8.4,36.03-8.4c0.61-0.01,1.25,0,1.77,0.01l0.96,0.03v-0.04h0.04c12.77,0,24.89,2.95,35.67,8.22
							c2.7,1.32,5.31,2.79,7.83,4.39l-39.33,50.74l-5.6,7.24l-5.64-7.21L59.57,26.12z"/>
						
							<rect x="118.85" y="116.69" transform="matrix(0.7905 -0.6125 0.6125 0.7905 -48.11 102.3306)" className="st7" width="13.31" height="9.57"/>
						<polygon className="st7" points="146.63,159.6 157.14,151.45 153.16,146.32 151.28,143.89 140.77,152.03 142.79,154.64 		"/>
						<polygon className="st7" points="116.28,98.72 107.86,109.58 110.38,112.84 111.45,114.21 121.97,106.05 118.8,101.97 		"/>
						
							<rect x="130.58" y="131.82" transform="matrix(0.7905 -0.6125 0.6125 0.7905 -54.9197 112.6844)" className="st7" width="13.3" height="9.57"/>
					</g>
				</g>
				</svg>
			</div>
		</div>
	);
}

export default Logo;